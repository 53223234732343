<div>
  <!-- 
<div class="mobileView">
  <ngx-ui-loader 
  [loaderId]="(typeOfScreen == 'sm' ? 'base' :'0')"
  [fgsColor]="'#336667'"
  [fgsColor]="'#336667'"
  [bgsOpacity]="1"
  [fgsPosition]="'center-center'"
  [fgsSize]="50"
  [overlayColor]="'rgba(255, 255, 255, 1)'"
  [hasProgressBar]="true"
  ></ngx-ui-loader>
</div> -->
  <div appHideToolbar>
    <div class="toolbar" #toolbar [hidden]="!toolbarBoolean">
      <!-- <div class="mobileView" *ngIf="appBarBoolean">
        <div class="app_bar_download">
          <div class="container py-2">
            <div class="d-flex align-items-center">
              <div class="order-1" (click)="appBarBoolean = false">
                <span class="icon icon-Icon-ionic-ios-close"></span>
              </div>
              <div
                class="order-2 logo_bar d-flex justify-content-center align-items-center"
              >
                <span
                  class="icon-Mabaat---White-Logo"
                  style="font-size: 10px"
                ></span>
              </div>
              <div class="order-3 text_bar">
                {{ "get_mabaat_app" | translate }}
              </div>
              <div class="order-4 ml-auto install_now" *ngIf="iOS()">
                <a
                  class="install_now"
                  href="https://apps.apple.com/eg/app/mabaat/id1569160279"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ "install" | translate }}
                </a>
              </div>
              <div class="order-4 ml-auto install_now" *ngIf="!iOS()">
                <a
                  class="install_now"
                  href="https://play.google.com/store/apps/details?id=com.mabaat.androidapp&hl=en_IN&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ "install" | translate }}
                </a>
              </div>
            </div>
          </div>
          <hr style="margin: 0px" />
        </div>
      </div> -->
      <div class="container">
        <div class="d-flex align-items-center mobile_center">
          <div class="order-1" style="min-width: 165px">
            <div class="mabatText">
              <div *ngIf="direction == 'ltr'">
                <span
                  style="cursor: pointer"
                  [routerLink]="['/']"
                  #icon
                  class="icon-Mabaat---White-Logo logo"
                ></span>
              </div>
              <div *ngIf="direction == 'rtl'">
                <span
                  style="cursor: pointer"
                  [routerLink]="['/']"
                  #icon
                  class="icon-tsXl5L01 logo"
                ></span>
              </div>
            </div>
          </div>
          <div
            class="order-2 webView"
            *ngIf="router.url == '/' || router.url == '/home'"
          >
            <div class="search" (click)="scrollToTop()">
              <div class="d-flex">
                <div class="order-1 mr-2">
                  <span
                    class="icon icon-search-toolbar"
                    style="line-height: 0.8"
                  ></span>
                </div>
                <div class="order-2">
                  <div class="text">{{ "where_are_you_go" | translate }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-3 d-flex align-items-center">
            <div class="order-1 langBox">
              <div class="profileMenu">
                <ng-container *ngTemplateOutlet="language"></ng-container>
              </div>
            </div>
            <div class="order-2 mx-4 webView">
              <div class="beAPartner" [routerLink]="['/partner']">
                {{ "Become_a_Host" | translate }}
              </div>
            </div>
            <div class="order-3 webView">
              <div class="profileMenu">
                <ng-container *ngTemplateOutlet="profile"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #profile>
    <div
      class="btn-group profile d-flex align-items-center"
      dropdown
      dropdownToggle
      style="display: flex !important"
    >
      <div class="order-1 mr-1">
        <div [hidden]="authService.isLoggedIn" class="name">
          {{ "join_us" | translate }}
        </div>
        <div [hidden]="!authService.isLoggedIn" class="name">
          {{ userProfile?.name.substring(0, 10) }}
        </div>
      </div>
      <div class="order-2 ml-auto" style="line-height: 0">
        <span
          class="iconProfile icon-Icon-awesome-user"
          style="background-color: #3467661a; padding: 8px; width: 30px"
        ></span>
        <!-- <span class="icon-user-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> -->
      </div>
      <ul
        [ngStyle]="{ 'min-height': authService.isLoggedIn ? '505px' : null }"
        *dropdownMenu
        class="dropdown-menu transformDropdow text-left"
        role="menu"
        aria-labelledby="button-animated"
        style="border-radius: 20px; width: 261px"
      >
        <li [hidden]="authService.isLoggedIn" role="menuitem">
          <a
            (click)="openModalLogin()"
            class="dropdown-item"
            style="padding: 17px; font-weight: bold"
            >{{ "login" | translate }}</a
          >
        </li>
        <li [hidden]="authService.isLoggedIn" role="menuitem">
          <a
            (click)="openModalSignin()"
            class="dropdown-item"
            style="padding: 17px; font-weight: bold"
            >{{ "create_an_account" | translate }}</a
          >
        </li>
        <!-- commented by Zeeshan
        <li [hidden]="!authService.isLoggedIn" role="menuitem">
          <a
            class="dropdown-item"
            style="padding: 17px; font-weight: bold"
            [routerLink]="['/manage/booking']"
            >{{ "manage_booking" | translate }}</a
          >
        </li>
        commented by Zeeshan -->
        <li [hidden]="!authService.isLoggedIn" role="menuitem">
          <a
            class="dropdown-item"
            style="padding: 17px; font-weight: bold"
            [routerLink]="['/favorite']"
            >{{ "favorite" | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a
            class="dropdown-item"
            style="padding: 17px; font-weight: bold"
            (click)="openModalCurrency()"
          >
            <div class="d-flex">
              <div class="order-1">{{ "currency" | translate }}</div>
              <div class="order-1 ml-auto">{{ currency }}</div>
            </div>
          </a>
        </li>

        <hr />
        <li [hidden]="!authService.isLoggedIn" role="menuitem">
          <a
            [routerLink]="['/profile']"
            class="dropdown-item"
            style="padding: 17px"
            >{{ "profile" | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a
            class="dropdown-item"
            style="padding: 17px"
            [routerLink]="['/about']"
            >{{ "about" | translate }}</a
          >
        </li>
        <li role="menuitem">
          <a
            class="dropdown-item"
            style="padding: 17px"
            [routerLink]="['/faq']"
            >{{ "faq" | translate }}</a
          >
        </li>

        <hr />
        <li role="menuitem">
          <a
            class="dropdown-item"
            style="padding: 17px"
            href=" https://wa.me/966920023564"
            target="_blank"
            >{{ "get_help" | translate }}</a
          >
        </li>
        <div *ngIf="authService.isLoggedIn"></div>
        <li *ngIf="authService.isLoggedIn" role="menuitem">
          <a
            (click)="authService.logout(); authService_Social.signOut()"
            class="dropdown-item"
            style="padding: 17px"
            >{{ "logout" | translate }}</a
          >
        </li>
        <li role="menuitem"></li>
      </ul>
    </div>
  </ng-template>

  <ng-template #language>
    <div class="webView" style="width: 42px">
      <div
        class="btn-group language d-flex align-items-center"
        dropdown
        dropdownToggle
        style="display: flex !important"
      >
        <div class="order-1">
          <span class="iconProfile icon-Vector"></span>
        </div>
        <ul
          *dropdownMenu
          class="dropdown-menu languageDropDpwn text-left"
          role="menu"
          aria-labelledby="button-animated"
          style="
            border-radius: 20px;
            border-radius: 20px;
            inset: 100% auto auto 0px;
            transform: translateY(13px);
            padding: 16px;
            padding-top: 22px;
            width: 210px;
            min-height: 117px;
          "
        >
          <li role="menuitem" (click)="changeLanguage('en')">
            <div class="d-flex align-items-center">
              <div class="order-1">
                <a
                  style="font-weight: bold"
                  [ngStyle]="{ color: direction == 'ltr' ? '#336667' : null }"
                >
                  English
                </a>
              </div>
              <div class="order-2 ml-auto" *ngIf="direction == 'ltr'">
                <span class="icon-check" style="color: #336667"></span>
              </div>
            </div>
          </li>
          <hr />
          <li role="menuitem" (click)="changeLanguage('ar')">
            <div class="d-flex align-items-center">
              <div class="order-1">
                <a
                  style="font-weight: bold"
                  [ngStyle]="{ color: direction == 'rtl' ? '#336667' : null }"
                >
                  {{ "arabic" | translate }}
                </a>
              </div>
              <div class="order-2 ml-auto" *ngIf="direction == 'rtl'">
                <span class="icon-check" style="color: #336667"></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobileView" style="width: 45px">
      <div
        class="d-flex align-items-center"
        (click)="openBottomSheetChangeLanguage()"
      >
        <div class="order-1 mr-1 langMobileText">
          <span *ngIf="direction == 'ltr'">EN</span>
          <span *ngIf="direction == 'rtl'">AR</span>
        </div>
        <div class="order-2" style="line-height: 0">
          <span class="iconLangMobile icon-Layer-2"></span>
        </div>
      </div>
    </div>
  </ng-template>

  <div appHideToolbarMobile>
    <div [hidden]="!toolbarBooleanMobile">
      <div class="mobileView">
        <div class="toolbar_mobile">
          <div class="d-flex w-100 h-100 align-items-center">
            <div class="order-1 text-center" [routerLink]="['/home']">
              <div class="group">
                <span
                  class="icon icon-home-1"
                  [ngClass]="{
                    icon_hover: router.url == '/' || router.url == '/home'
                  }"
                ></span>
                <div
                  class="text"
                  [ngClass]="{
                    text_hover: router.url == '/' || router.url == '/home'
                  }"
                >
                  {{ "home_mobile" | translate }}
                </div>
              </div>
            </div>
            <div
              class="order-2 text-center ml-auto"
              [routerLink]="['/properties']"
            >
              <div class="group" tabindex="2">
                <span
                  class="icon icon-compass"
                  style="font-size: 24px"
                  [ngClass]="{ icon_hover: router.url == '/properties' }"
                ></span>
                <div
                  class="text"
                  [ngClass]="{ text_hover: router.url == '/properties' }"
                >
                  {{ "explore" | translate }}
                </div>
              </div>
            </div>
            <div
              class="order-3 text-center ml-auto"
              [routerLink]="['/favorite']"
            >
              <div class="group" tabindex="2">
                <span
                  *ngIf="router.url == '/favorite'"
                  class="icon icon-head_green"
                  style="font-size: 24px"
                  ><span class="path1"></span><span class="path2"></span
                ></span>
                <span
                  *ngIf="router.url != '/favorite'"
                  class="icon icon-headt_toolbar"
                  style="font-size: 24px"
                  [ngClass]="{ icon_hover: router.url == '/favorite' }"
                ></span>
                <div
                  class="text"
                  [ngClass]="{ text_hover: router.url == '/favorite' }"
                >
                  {{ "favorite" | translate }}
                </div>
              </div>
            </div>
            <div
              class="order-4 text-center ml-auto"
              [routerLink]="['/mobile/settings']"
            >
              <div class="group">
                <span
                  class="icon icon-more-horizontal-outline-1"
                  [ngClass]="{ icon_hover: router.url == '/settings' }"
                ></span>
                <div
                  class="text"
                  [ngClass]="{ text_hover: router.url == '/mobile/settings' }"
                >
                  {{ "more" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
